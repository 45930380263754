import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'app-active-route-marker',
    imports: [],
    template: ` <div class="h-full w-1 rounded-l-[100%_10%] bg-twElectricPurple"></div> `,
    host: { class: 'h-full' },
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActiveRouteMarkerComponent {}
