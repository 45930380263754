import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'app-payment-icon',
    imports: [],
    template: `
        <svg
            fill="currentColor"
            height="100%"
            viewBox="0 0 18 19"
            width="100%"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M3.375 3.55127C2.13236 3.55127 1.125 4.55863 1.125 5.80127V6.36377H16.875V5.80127C16.875 4.55863 15.8676 3.55127 14.625 3.55127H3.375Z" />
            <path
                clip-rule="evenodd"
                d="M16.875 8.05127H1.125V13.6763C1.125 14.9189 2.13236 15.9263 3.375 15.9263H14.625C15.8676 15.9263 16.875 14.9189 16.875 13.6763V8.05127ZM3.375 10.8638C3.375 10.5531 3.62684 10.3013 3.9375 10.3013H8.4375C8.74816 10.3013 9 10.5531 9 10.8638C9 11.1744 8.74816 11.4263 8.4375 11.4263H3.9375C3.62684 11.4263 3.375 11.1744 3.375 10.8638ZM3.9375 12.5513C3.62684 12.5513 3.375 12.8031 3.375 13.1138C3.375 13.4244 3.62684 13.6763 3.9375 13.6763H6.1875C6.49816 13.6763 6.75 13.4244 6.75 13.1138C6.75 12.8031 6.49816 12.5513 6.1875 12.5513H3.9375Z"
                fill-rule="evenodd" />
        </svg>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaymentIconComponent {}
