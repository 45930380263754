import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'app-audience-icon',
    imports: [],
    template: `
        <svg
            fill="currentColor"
            height="100%"
            viewBox="0 0 18 19"
            width="100%"
            xmlns="http://www.w3.org/2000/svg">
            <path
                clip-rule="evenodd"
                d="M6.1875 5.80127C6.1875 4.24797 7.4467 2.98877 9 2.98877C10.5533 2.98877 11.8125 4.24797 11.8125 5.80127C11.8125 7.35457 10.5533 8.61377 9 8.61377C7.4467 8.61377 6.1875 7.35457 6.1875 5.80127Z"
                fill-rule="evenodd" />
            <path
                clip-rule="evenodd"
                d="M11.8125 8.05127C11.8125 6.80863 12.8199 5.80127 14.0625 5.80127C15.3051 5.80127 16.3125 6.80863 16.3125 8.05127C16.3125 9.29391 15.3051 10.3013 14.0625 10.3013C12.8199 10.3013 11.8125 9.29391 11.8125 8.05127Z"
                fill-rule="evenodd" />
            <path
                clip-rule="evenodd"
                d="M1.6875 8.05127C1.6875 6.80863 2.69486 5.80127 3.9375 5.80127C5.18014 5.80127 6.1875 6.80863 6.1875 8.05127C6.1875 9.29391 5.18014 10.3013 3.9375 10.3013C2.69486 10.3013 1.6875 9.29391 1.6875 8.05127Z"
                fill-rule="evenodd" />
            <path
                clip-rule="evenodd"
                d="M4.7324 12.0769C5.63087 10.6721 7.20626 9.73877 9 9.73877C10.794 9.73877 12.3695 10.6723 13.2679 12.0774C13.8891 13.0488 14.1578 14.2202 14.0317 15.3622C14.012 15.5407 13.9083 15.699 13.7526 15.7884C12.3521 16.592 10.7288 17.0513 9 17.0513C7.27119 17.0513 5.64794 16.592 4.24743 15.7884C4.09169 15.699 3.98797 15.5407 3.96827 15.3622C3.8422 14.2199 4.11106 13.0484 4.7324 12.0769Z"
                fill-rule="evenodd" />
            <path
                d="M3.81171 11.429C3.80268 11.4429 3.7937 11.4568 3.78478 11.4708C3.06069 12.6029 2.72931 13.9513 2.83104 15.2805C2.375 15.2113 1.93252 15.0998 1.50782 14.9503L1.4216 14.9199C1.26432 14.8645 1.15442 14.7217 1.14119 14.5555L1.13394 14.4643C1.12801 14.3898 1.125 14.3146 1.125 14.2388C1.125 12.7276 2.31678 11.4948 3.81171 11.429Z" />
            <path
                d="M15.1692 15.2805C15.2709 13.9515 14.9397 12.6034 14.2159 11.4714C14.2068 11.4572 14.1977 11.4431 14.1886 11.429C15.6834 11.495 16.875 12.7277 16.875 14.2388C16.875 14.3146 16.872 14.3898 16.8661 14.4643L16.8588 14.5555C16.8456 14.7217 16.7357 14.8645 16.5784 14.9199L16.4922 14.9503C16.0676 15.0998 15.6252 15.2112 15.1692 15.2805Z" />
        </svg>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AudienceIconComponent {}
