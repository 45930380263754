
import { Component, inject } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { environment } from '@environments/environment';

@Component({
    selector: 'app-page-not-found',
    imports: [RouterModule],
    template: `
        <div
            class="mb-20 mt-[10vh] flex flex-1 flex-col items-center justify-center gap-5 text-4xl">
            <span>404 Page not found </span>
            <button class="text-lg" (click)="handleClick()">Go to home</button>
        </div>
    `,
    styles: [':host{display: flex; flex: 1 1 0}']
})
export class PageNotFoundComponent {
    environment = environment;

    router = inject(Router);

    handleClick() {
        this.router.navigate([environment.front.home]);
    }
}
