import { Component, EventEmitter, Input, Output, computed, inject } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { UserService } from '@core/services/admin/user.service';
import { ResetAppStateService } from '@core/services/reset-app-state.service';
import { AuthService } from '@features/auth/auth.service';
import { openCloseMenuFromTop } from '@shared/animations';
import { AvatarComponent } from '@shared/components/avatar/avatar.component';
import { LogoutIconsComponent } from '@shared/components/icons/logout-icons/logout-icon.component';
import { handleImgLoadError } from '@shared/utils/image';

@Component({
    selector: 'app-avatar-menu',
    imports: [LogoutIconsComponent, RouterModule, AvatarComponent],
    templateUrl: './avatar-menu.component.html',
    animations: [openCloseMenuFromTop],
})
export class AvatarMenuComponent {
    private readonly authS = inject(AuthService);
    private readonly userS = inject(UserService);
    private readonly router = inject(Router);

    @Input() isMenuOpen = false;
    profileImg = computed(() => this.userS.user()?.avatar);
    name = computed(() => this.userS.user()?.name);
    email = computed(() => this.userS.user()?.email);

    @Output() toggleMenu = new EventEmitter<Event>();

    logOut() {
        this.authS.logout();
    }

    handleImgErr(event: Event) {
        handleImgLoadError(event, 'assets/default-avatar.png');
    }

    handleOptionClick(ev: Event, path: string) {
        this.toggleMenu.emit(ev);

        if (path) this.router.navigateByUrl(path);
    }
}
