import { Component } from '@angular/core';


@Component({
    selector: 'app-square-checkmark',
    imports: [],
    templateUrl: './square-checkmark.component.html',
    styles: []
})
export class SquareCheckmarkComponent {}
