
import { ChangeDetectionStrategy, Component, inject, output } from '@angular/core';
import { Router } from '@angular/router';
import { CrossIconComponent } from '@shared/components/icons/cross-icon/cross-icon.component';

@Component({
    selector: 'set-cancel-sub-success-modal',
    imports: [CrossIconComponent],
    templateUrl: './cancel-sub-success-modal.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CancelSubSuccessModalComponent {
    private router = inject(Router);
    onClose = output<void>();

    handleClose() {
        this.router.navigate([], {
            queryParams: {
                modal: null,
            },
            queryParamsHandling: 'merge',
        });
        this.onClose.emit();
    }
}
