@if (isMenuOpen) {
    <div
        @openClose
        class="min-w-[207px] rounded-lg bg-white p-3 shadow-popMenu"
        tabindex="0"
        (click)="$event.stopPropagation()"
        (keyup.enter)="$event.stopPropagation()">
        <div>
            <div class="relative flex items-center gap-2 pb-[10px] text-left">
                <app-avatar class="ml-1" size="lg" [name]="name()" [src]="profileImg()" />
                <div class="mt-1">
                    <p class="pt-[4px] text-sm font-medium text-black">
                        {{ name() }}
                    </p>
                    <p class="mb-[3px] text-sm text-gray-500">{{ email() }}</p>
                </div>
            </div>

            <div class="grid grid-cols-[20px_1fr] items-center gap-x-2 gap-y-1">
                <button
                    class="group col-span-2 grid grid-cols-subgrid rounded-lg py-1 hover:bg-twLightPurple"
                    (click)="logOut()">
                    <app-logout-icons
                        class="ml-3 stroke-twGrayIcons group-hover:stroke-twElectricPurple" />
                    <span class="ml-2 text-start text-sm text-twGrayIcons group-hover:text-black">
                        Log out
                    </span>
                </button>
            </div>
        </div>
    </div>
}
