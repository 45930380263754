import { inject } from '@angular/core';
import { CanDeactivateFn } from '@angular/router';
import { SETTINGS_SLUGS } from '@routes/routes.types';
import { BillingService } from '../services/billing.service';

export const subscriptionCompletedGuard: CanDeactivateFn<boolean> = (
    _component,
    _route,
    _state,
    nextState
) => {
    const billingS = inject(BillingService);
    const isExpired = billingS.periodEndExpired();
    const isCheckoutRoute = nextState.url.includes(SETTINGS_SLUGS.checkout);
    const isValid = !isExpired || isCheckoutRoute;

    return isValid;
};
