import { Component } from '@angular/core';


@Component({
    selector: 'app-logout-icons',
    imports: [],
    templateUrl: './logout-icon.component.html',
    styleUrls: []
})
export class LogoutIconsComponent {}
