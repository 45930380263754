import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'app-like-icon',
    imports: [],
    template: `
        <svg
            fill="currentColor"
            height="100%"
            viewBox="0 0 18 19"
            width="100%"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M5.61961 14.4961C5.30117 14.4961 5.00472 14.3187 4.88819 14.0224C4.63762 13.3851 4.5 12.691 4.5 11.9648C4.5 10.653 4.94905 9.4461 5.70183 8.48938C5.81501 8.34554 5.98092 8.25765 6.15161 8.19157C6.50622 8.05428 6.8194 7.80585 7.06057 7.49834C7.64044 6.75897 8.37127 6.14364 9.20665 5.69875C9.74825 5.41032 10.2182 4.98181 10.4461 4.41207C10.6056 4.01342 10.6875 3.58799 10.6875 3.15863V2.68359C10.6875 2.37293 10.9393 2.12109 11.25 2.12109C12.182 2.12109 12.9375 2.87661 12.9375 3.80859C12.9375 4.67232 12.7428 5.49057 12.395 6.22187C12.1958 6.64063 12.4752 7.18359 12.9389 7.18359H15.2833C16.0533 7.18359 16.7425 7.70409 16.824 8.46974C16.8577 8.78641 16.875 9.10798 16.875 9.43359C16.875 11.5693 16.1311 13.5313 14.8883 15.0745C14.5975 15.4355 14.1481 15.6211 13.6847 15.6211H10.6727C10.3099 15.6211 9.94956 15.5626 9.60542 15.4479L7.26958 14.6693C6.92544 14.5546 6.56506 14.4961 6.20231 14.4961H5.61961Z" />
            <path
                d="M1.74862 8.66643C1.3461 9.6878 1.125 10.8005 1.125 11.9648C1.125 12.8799 1.26156 13.7631 1.51542 14.5951C1.70994 15.2326 2.32813 15.6211 2.99467 15.6211H3.67551C4.00965 15.6211 4.21603 15.2474 4.06817 14.9477C3.62436 14.0483 3.375 13.0357 3.375 11.9648C3.375 10.6837 3.73191 9.48594 4.35173 8.46553C4.53548 8.16303 4.33053 7.74609 3.97659 7.74609H3.18702C2.563 7.74609 1.97741 8.08587 1.74862 8.66643Z" />
        </svg>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LikeIconComponent {}
