<div #spacer class="w-16"></div>

<aside
    class="group absolute bottom-0 top-0 z-50 flex w-16 flex-col justify-between overflow-hidden border-r-[1px] bg-twComponentBackground text-twGrayDark transition-[width] hover:w-44"
    aria-label="Sidebar">
    <div class="flex flex-grow flex-col">
        <button
            class="flex max-w-[100%] items-center pb-20 pt-8"
            (click)="handleAvatarClick($event)">
            <app-avatar class="ml-[1.37rem] mr-3" size="sm" [name]="name()" [src]="profileImg()" />
            <div class="min-w-[136px] opacity-0 transition-[width_opacity] group-hover:opacity-100">
                <div
                    class="overflow-hidden whitespace-normal break-all text-start font-medium text-twTextPrimary">
                    {{ (accountS.account()?.account_name | titlecase) || '' }}
                </div>
            </div>
        </button>

        <menu class="flex flex-grow flex-col">
            @for (opt of OPTS; track opt.link; let last = $last) {
                <ul
                    class="hover:bg-twLightPurple"
                    [class.mb-9]="opt.link === SLUGS.SETTINGS"
                    [class.mt-auto]="opt.link === SLUGS.SETTINGS">
                    <a
                        class="group relative flex w-full items-center justify-between py-2"
                        routerLinkActive="active"
                        [routerLink]="opt.link">
                        <div class="flex items-center">
                            <div
                                class="ml-6 mr-3 flex size-[18px] items-center justify-center text-twGray group-[.active]:text-twElectricPurple">
                                <ng-container [ngComponentOutlet]="opt.icon" />
                            </div>
                            <span
                                class="link-tex whitespace-nowrap opacity-0 transition-[width_opacity] group-hover:opacity-100 group-[.active]:text-twElectricPurple">
                                {{ opt.title }}
                            </span>
                        </div>
                        <app-active-route-marker
                            class="absolute bottom-0 right-0 top-0 hidden group-[.active]:block" />
                    </a>
                </ul>
            }
        </menu>
    </div>
</aside>

<app-avatar-menu
    class="absolute left-20 top-4 z-[51]"
    [isMenuOpen]="isMenuOpen()"
    (toggleMenu)="handleAvatarClick($event)" />
