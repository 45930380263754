import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'app-inbox-icon',
    imports: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `<svg
        fill="currentColor"
        height="100%"
        viewBox="0 0 18 19"
        width="100%"
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M3.6848 2.73244C5.24078 2.53043 6.82722 2.42627 8.43771 2.42627C10.048 2.42627 11.6343 2.53041 13.1902 2.73238C14.6318 2.91954 15.6588 4.1283 15.7442 5.52748C15.4999 5.44587 15.2409 5.39174 14.97 5.36926C13.9286 5.28282 12.8755 5.23877 11.8125 5.23877C10.7495 5.23877 9.69639 5.28282 8.65499 5.36925C6.88628 5.51605 5.625 7.01227 5.625 8.69493V11.909C5.625 13.177 6.34101 14.3382 7.4496 14.8972L5.46025 16.8865C5.29937 17.0474 5.05743 17.0955 4.84724 17.0085C4.63705 16.9214 4.5 16.7163 4.5 16.4888V13.4667C4.22737 13.4375 3.95563 13.4053 3.68481 13.3701C2.1785 13.1746 1.125 11.8637 1.125 10.3857V5.7168C1.125 4.23881 2.17849 2.92801 3.6848 2.73244Z" />
        <path
            d="M11.8125 6.36377C10.7806 6.36377 9.75853 6.40653 8.74804 6.4904C7.59357 6.58622 6.75 7.56588 6.75 8.69493V11.909C6.75 13.0398 7.59597 14.02 8.75191 14.1144C9.6848 14.1905 10.6275 14.2322 11.5788 14.238L13.6648 16.324C13.8256 16.4849 14.0676 16.533 14.2778 16.446C14.488 16.3589 14.625 16.1538 14.625 15.9263V14.1338C14.7078 14.1276 14.7904 14.1212 14.873 14.1144C16.029 14.0201 16.875 13.0399 16.875 11.9091V8.69493C16.875 7.56589 16.0314 6.58623 14.877 6.4904C13.8665 6.40653 12.8444 6.36377 11.8125 6.36377Z" />
    </svg> `
})
export class InboxIconComponent {}
