import { ChangeDetectionStrategy, Component, computed, inject } from '@angular/core';
import TEXT from '@content/settings.en.json';
import { SUB_STATUS } from '@core/models/admin/admin-account.types';
import { WarningHexComponent } from '@shared/components/icons/warning-hex/warning-hex.component';
import { BillingService } from '../../services/billing.service';

@Component({
    selector: 'app-trial-warning',
    imports: [WarningHexComponent],
    templateUrl: './trial-warning.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrialWarningComponent {
    private readonly billingS = inject(BillingService);

    readonly TEXT = TEXT;

    readonly daysUntilDue = this.billingS.daysUntilDue;
    readonly periodEnd = this.billingS.periodEnd;
    readonly subStatus = this.billingS.subStatus;

    readonly text = computed(() => {
        const isExpired = this.subStatus() === SUB_STATUS.INCOMPLETE_EXPIRED;
        if (isExpired) {
            return TEXT.trialWarning.expired;
        } else {
            const daysUntilDue = this.daysUntilDue()?.toString() ?? '';
            return TEXT.trialWarning.nearExpiry
                .replace('{periodEnd}', this.periodEnd())
                .replace('{daysUntilDue}', daysUntilDue);
        }
    });

    handleSubscribe() {
        this.billingS.goToInitialSubscribe();
    }
}
