import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'app-campaigns-icon',
    imports: [],
    template: `
        <svg
            fill="currentColor"
            height="100%"
            viewBox="0 0 18 19"
            width="100%"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12.6609 3.99798C11.1075 4.61941 9.44379 5.02381 7.70697 5.17367C7.20636 5.21686 6.69957 5.23892 6.1875 5.23892H5.625C3.45038 5.23892 1.6875 7.0018 1.6875 9.17642C1.6875 11.1261 3.10458 12.7449 4.96498 13.0588C5.21568 14.0856 5.56354 15.0742 5.99823 16.0145C6.34628 16.7673 7.25386 17.0045 7.93522 16.6111L8.42745 16.3269C9.08752 15.9458 9.3017 15.1324 9.00651 14.4758C8.83036 14.0841 8.67316 13.6819 8.53606 13.2707C9.97411 13.4642 11.3564 13.833 12.6609 14.3549C13.2052 12.7278 13.5 10.9866 13.5 9.17641C13.5 7.36627 13.2052 5.62499 12.6609 3.99798Z" />
            <path
                d="M13.6955 3.54445C14.2981 5.31122 14.625 7.20563 14.625 9.17641C14.625 11.1472 14.2981 13.0416 13.6955 14.8084C13.6541 14.9297 13.6115 15.0505 13.5676 15.1706C13.4608 15.4623 13.6107 15.7854 13.9025 15.8921C14.1942 15.9989 14.5173 15.849 14.624 15.5573C14.7291 15.2704 14.8272 14.9802 14.9182 14.6869C15.311 13.4218 15.572 12.099 15.6855 10.7347C16.0732 10.3309 16.3125 9.78141 16.3125 9.17642C16.3125 8.57143 16.0732 8.02195 15.6855 7.61811C15.572 6.2538 15.311 4.93102 14.9182 3.66596C14.8272 3.37265 14.7291 3.08245 14.624 2.79557C14.5173 2.50384 14.1942 2.35391 13.9025 2.4607C13.6107 2.56749 13.4608 2.89055 13.5676 3.18228C13.6115 3.30237 13.6541 3.42314 13.6955 3.54445Z" />
        </svg>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CampaignsIconComponent {}
