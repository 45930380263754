import { Routes } from '@angular/router';

export const DEPARTMENTS_SLUGS = {
    table: 'table',
    form: 'form',
} as const;

export const DEPARTMENTS_ROUTES: Routes = [
    {
        path: DEPARTMENTS_SLUGS.table,
        title: 'Table',
        loadComponent: () =>
            import(
                '@features/settings/departments/departments-table/departments-table.component'
            ).then(m => m.DepartmentsTableComponent),
    },
    {
        path: DEPARTMENTS_SLUGS.form,
        title: 'Form',
        loadComponent: () =>
            import('@features/settings/departments/department-form/department-form.component').then(
                m => m.DepartmentFormComponent
            ),
    },
    {
        path: '',
        pathMatch: 'full',
        redirectTo: DEPARTMENTS_SLUGS.table,
    },
];
