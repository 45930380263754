import {
    ChangeDetectionStrategy,
    Component,
    computed,
    inject,
    OnInit,
    output,
    signal,
} from '@angular/core';
import TEXT from '@content/settings.en.json';
import { ModalBgComponent } from '@shared/components/modal-bg/modal-bg.component';
import { SwitchComponent } from '@shared/components/switch/switch.component';
import { BILLING_PLAN_RECURRING, BillingPlanRecurring } from '../../billing.types';
import { BillingService } from '../../services/billing.service';
import { PricingPlanCardComponent } from '../pricing-plan-card/pricing-plan-card.component';
@Component({
    selector: 'app-upgrade-pricing-modal',
    imports: [ModalBgComponent, PricingPlanCardComponent, SwitchComponent],
    templateUrl: './upgrade-pricing-modal.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UpgradePricingModalComponent implements OnInit {
    private readonly billingS = inject(BillingService);

    readonly TEXT = TEXT;

    readonly onClose = output<void>();

    readonly growthPlans = this.billingS.growthPlans;
    readonly applyDiscount = signal(false);

    readonly recurring = computed<BillingPlanRecurring>(() =>
        this.applyDiscount() ? BILLING_PLAN_RECURRING.year : BILLING_PLAN_RECURRING.month
    );

    ngOnInit(): void {
        this.billingS.reqPlans();
    }

    toggleApplyDiscount() {
        this.applyDiscount.update(v => !v);
    }
}
