<div class="absolute z-10 flex w-full justify-center">
    <div
        class="relative flex h-10 w-fit items-center rounded-b border-x border-b border-twClassicOrange bg-twLightOrange px-2 py-1.5">
        <app-warning-hex class="mr-4 size-5 text-twClassicOrange" />
        <p class="mb-[-1px] mr-4 font-light">{{ text() }}</p>

        <button
            class="mt-[-1px] flex h-[26px] w-[129px] justify-center rounded-card bg-twClassicOrange px-3 py-1 font-medium text-twTextInverse"
            (click)="handleSubscribe()">
            <span class="mt-[-1px]">{{ TEXT.billing.subscribeNow }}</span>
        </button>
    </div>
</div>
