import TEXT from '@content/core.en.json';
import { MAIN_SLUGS } from '@routes/routes.types';
import { InsightsIconComponent } from '@shared/components/icons/insights-icon/insights-icon.component';
import { AudienceIconComponent } from '@shared/components/icons/audience-icon/audience-icon.component';
import { AutomationIconComponent } from '@shared/components/icons/automation-icon.component';
import { DashboardIconComponent } from '@shared/components/icons/dashboard-icon/dashboard-icon.component';
import { InboxIconComponent } from '@shared/components/icons/inbox-icon/inbox-icon.component';
import { CampaignsIconComponent } from '@shared/components/icons/campaigns-icon/campaigns-icon.component';
import { PaymentIconComponent } from '@shared/components/icons/payment-icon/payment-icon.component';
import { ProductsIconComponent } from '@shared/components/icons/products-icon/products-icon.component';
import { GearIconComponent } from '@shared/components/icons/gear-icon/gear-icon.component';
import { LikeIconComponent } from '@shared/components/icons/like-icon/like-icon.component';

export const SIDEBAR_OPTS = [
    {
        title: TEXT.sidebar.dashboard,
        icon: DashboardIconComponent,
        link: MAIN_SLUGS.DASHBOARD,
    },
    {
        title: TEXT.sidebar.inbox,
        icon: InboxIconComponent,
        link: MAIN_SLUGS.SMART_INBOX,
    },
    {
        title: TEXT.sidebar.products,
        icon: ProductsIconComponent,
        link: MAIN_SLUGS.PRODUCTS,
    },
    {
        title: TEXT.sidebar.audience,
        icon: AudienceIconComponent,
        link: MAIN_SLUGS.AUDIENCE,
    },
    {
        title: TEXT.sidebar.campaigns,
        icon: CampaignsIconComponent,
        link: MAIN_SLUGS.CAMPAIGNS,
    },
    {
        title: TEXT.sidebar.automation,
        icon: AutomationIconComponent,
        link: MAIN_SLUGS.AUTOMATION,
    },
    {
        title: TEXT.sidebar.payments,
        icon: PaymentIconComponent,
        link: MAIN_SLUGS.PAYMENT,
    },
    {
        title: TEXT.sidebar.social,
        icon: LikeIconComponent,
        link: MAIN_SLUGS.SOCIAL_ANALYTICS,
    },
    {
        title: TEXT.sidebar.insights,
        icon: InsightsIconComponent,
        link: MAIN_SLUGS.INSIGHTS,
    },
    {
        title: TEXT.sidebar.settings,
        icon: GearIconComponent,
        link: MAIN_SLUGS.SETTINGS,
    },
];
